import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  ArrowDownTrayIcon,
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ArrowUpOnSquareIcon,
  PencilIcon,
  PrinterIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline';
import Polygon from '@arcgis/core/geometry/Polygon';
import SpatialReference from '@arcgis/core/geometry/SpatialReference';
import { project } from '@arcgis/core/geometry/projection';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import turfArea from '@turf/area';
import useEsriMap from '../../hooks/esriMap/useEsriMap';
import RootItemContext from '../../context/RootItemContext';
import createPdf from '../../reports/functions/createPdf';
import MapSearch from '../map/MapSearch';
import request from '../../utils/fetch';
import roundTo from '../../utils/roundTo';
import '../../assets/scss/MapboxGL.scss';
import '../../assets/scss/GenericMap.scss';

const apiKey = 'AAPTxy8BH1VEsoebNVZXo8HurPULMBokAdvq6kbizvgxkGnMhCyJUsaN_ofaAuuvuYXckfIAZylnA2g8KdLcS51EN67fUPwBpmrEAbl_-rwJF1m8l7LFZWZnBsEazeOU20T05XfZa9NDBeW-T-EciMD1MDDwaeqFODpFu0vib9MBwpqL5Mkpn-WCXrv-rkdwK85PxC_zFUIABT0eu-s8FY2-ERc9Nna46edvRDcr8c1x0pQ.AT1_JWuSZvXB';

const area = (geojson) => {
  return roundTo(turfArea(geojson) / 4046.86, 1);
};

const formatCoordinates = (coords) => {
  const newCoords = [];
  if (coords.length === 1) {
    newCoords.push(coords[0].length === 1 ? coords[0][0] : coords[0]);
  } else {
    coords.forEach((item) => {
      newCoords.push(item.length > 1 ? [item] : item);
    });
  }
  return newCoords;
};

const EsriMap = (props) => {
  const {
    height = 'h-full',
    layerSwitch,
    expandable,
    enableEditor,
    printable = false,
    searchable,
    editingEndpoint,
    canSaveGeoJSON,
    exposeMapView,
    ...restProps
  } = props;
  const { refresh: refreshItem } = useContext(RootItemContext);

  const [expanded, setExpanded] = useState(false);
  const [editing, setEditing] = useState(false);
  const [importGeometry, setImportGeometry] = useState([]);
  const [coordinates, setCoordinates] = useState({ x: 0, y: 0 });
  const [polygonArea, setPolygonArea] = useState(null);
  const editorUpdateBtn = useRef();
  const editingEvent = useRef();

  const editingPolygon = editingEndpoint === 'certs'
    || editingEndpoint === 'retirements'
    || printable;

  const {
    certGeo, wellsGeo, flowmetersGeo, chemigationsGeo, retirementGeo,
  } = restProps;

  const updateGeometry = async (geometry, id) => {
    await request({
      method: 'post',
      url: `${editingEndpoint}/${id}/geom`,
      data: { geometry },
    });
    refreshItem();
    setExpanded(false);
    setEditing((x) => !x);
  };

  const handleUpdateButtonClick = () => {
    updateGeometry(
      {
        coordinates: formatCoordinates(editingEvent.current.coordinates),
        type: editingPolygon
          ? editingEvent.current.coordinates.length > 1
            ? 'MultiPolygon'
            : 'Polygon'
          : 'Point',
      },
      editingEvent.current.id,
    );
  };

  const handlePolygonMove = async (graphics) => {
    const coordinates = editingEvent.current?.coordinates
      ? editingEvent.current?.coordinates
      : JSON.parse(JSON.stringify(certGeo?.geometry?.coordinates || []));
    const newGeometry = graphics[0].geometry;
    if (newGeometry.spatialReference.wkid !== 4326) {
      await Promise.all(
        graphics.map(async (graphic) => {
          const geographicGeometry = await project(
            graphic.geometry,
            SpatialReference.WGS84,
          );
          coordinates[graphic.attributes.ObjectID - 1] = geographicGeometry.rings;
        }),
      );
    } else {
      coordinates[graphics[0].attributes.ObjectID - 1] = newGeometry.rings;
    }
    editingEvent.current = {
      coordinates,
      id: graphics[0].attributes._id,
    };
  };

  const handleEditorSubmit = useCallback(
    async (event, view) => {
      const { detail, layer } = event;
      const {
        graphics, aborted, state, tool, toolEventInfo,
      } = detail;

      if (
        (tool === 'transform' || tool === 'reshape')
        && state === 'active'
        && (toolEventInfo?.type === 'move-start'
          || toolEventInfo?.type === 'reshape-start')
      ) {
        setTimeout(() => {
          const calciteFlow = document.querySelectorAll('calcite-flow-item');
          if (calciteFlow?.[1]?.lastChild) {
            const editorActions = calciteFlow?.[1]?.lastChild;
            if (editorActions) {
              const calciteButtons = editorActions.querySelectorAll('calcite-button');
              if (calciteButtons?.length === 2) {
                editorUpdateBtn.current = calciteButtons[0];
                const updateButton = calciteButtons[0];
                updateButton.removeEventListener(
                  'click',
                  handleUpdateButtonClick,
                );
                updateButton.addEventListener('click', handleUpdateButtonClick);
              }
            }
          }
        }, 200);
      }

      if (tool === 'reshape') {
        if (toolEventInfo?.type === 'reshape-stop') {
          const coordinates = editingEvent.current?.coordinates
            ? editingEvent.current?.coordinates
            : JSON.parse(JSON.stringify(certGeo?.geometry?.coordinates || []));
          if (
            toolEventInfo.mover?.attributes?.pointIndex
            && toolEventInfo.mover?.geometry?.latitude
            && toolEventInfo.mover?.geometry?.longitude
          ) {
            const newPointCoordinate = [
              parseFloat(toolEventInfo.mover?.geometry?.longitude),
              parseFloat(toolEventInfo.mover?.geometry?.latitude),
            ];
            const selectedGeometry = coordinates.length > 1 ? coordinates[graphics[0].attributes.ObjectID - 1][0] : coordinates[graphics[0].attributes.ObjectID - 1];
            if (
              toolEventInfo.mover?.attributes?.pointIndex
              === selectedGeometry.length - 1
            ) {
              selectedGeometry.splice(
                toolEventInfo.mover?.attributes?.pointIndex,
                0,
                newPointCoordinate,
              );
            } else {
              selectedGeometry[
                toolEventInfo.mover?.attributes?.pointIndex
              ] = newPointCoordinate;
            }
          }
          editingEvent.current = {
            coordinates,
            id: graphics[0].attributes._id,
          };
          setPolygonArea(
            coordinates.length
              ? area(coordinates.length > 1 ? {
                type: 'FeatureCollection',
                features: coordinates.map((item) => ({
                  type: 'Feature',
                  geometry: {
                    type: 'Polygon',
                    coordinates:
                        coordinates.length > 1 ? item : [item],
                  },
                  properties: certGeo.properties,
                })),
              } : {
                type: 'Feature',
                geometry: {
                  type: 'Polygon',
                  coordinates,
                },
                properties: certGeo.properties,
              })
              : null,
          );
        } else if (toolEventInfo?.type === 'move-stop') {
          if (editingPolygon) {
            await handlePolygonMove(graphics);
          } else {
            const newMapPoint = graphics[0].geometry;
            editingEvent.current = {
              coordinates: [newMapPoint.longitude, newMapPoint.latitude],
              id: graphics[0].attributes._id,
            };
          }
        }
      } else if (tool === 'transform') {
        if (toolEventInfo?.type === 'move-stop') {
          await handlePolygonMove(graphics);
        }
      }

      if (state === 'complete' && tool === 'reshape') {
        editingEvent.current = null;
      }

      if (aborted && layer) {
        let geometry = {};
        let id = null;

        if (editingEndpoint === 'certs') {
          id = certGeo?.properties?.id;
          const coordinates = JSON.parse(
            JSON.stringify(certGeo?.geometry?.coordinates || []),
          );
          await Promise.all(
            graphics.map(async ({ geometry, attributes }) => {
              if (
                attributes?.displayId
                && attributes?.ObjectID !== null
                && attributes?.ObjectID !== undefined
              ) {
                coordinates.splice(attributes.ObjectID - 1, 1);
              } else {
                // Check if the geometry needs to be projected
                if (geometry.spatialReference.wkid !== 4326) {
                  // Project the geometry to WGS84 (latitude/longitude)
                  const geographicGeometry = await project(
                    geometry,
                    SpatialReference.WGS84,
                  );
                  coordinates.push(geographicGeometry.rings);
                } else {
                  // If it's already in WGS84, just return the rings
                  coordinates.push(geometry.rings);
                }
              }
            }),
          );

          if (!coordinates.length) {
            geometry = null;
            setPolygonArea(null);
          } else {
            geometry.coordinates = formatCoordinates(coordinates);
            geometry.type = editingPolygon
              ? coordinates.length > 1
                ? 'MultiPolygon'
                : 'Polygon'
              : 'Point';

            setPolygonArea(
              geometry?.coordinates?.length
                ? area({
                  type: 'FeatureCollection',
                  features: geometry.coordinates.map((item) => ({
                    type: 'Feature',
                    geometry: {
                      type: 'Polygon',
                      coordinates:
                          geometry.coordinates.length > 1 ? item : [item],
                    },
                    properties: certGeo.properties,
                  })),
                })
                : null,
            );
          }
        } else {
          switch (editingEndpoint) {
            case 'wells':
              id = wellsGeo?.properties?.id;
              break;
            case 'flowmeters':
              id = flowmetersGeo?.properties?.id;
              break;
            case 'chemigations':
              id = chemigationsGeo?.properties?.id;
              break;
            default:
              id = retirementGeo?.properties?.id;
              break;
          }

          if (
            graphics?.[0]?.attributes?.displayId
            && graphics?.[0]?.attributes?.id
          ) {
            geometry = null;
          } else {
            geometry.type = 'Point';
            geometry.coordinates = [
              graphics[0].geometry.longitude,
              graphics[0].geometry.latitude,
            ];
          }
        }

        updateGeometry(geometry, id);
      }
    },
    [certGeo, wellsGeo, flowmetersGeo, retirementGeo, editingEndpoint],
  );

  const {
    mapDiv,
    mapView,
    mapLoaded,
    sketchGraphicLayerRef,
    mapRef,
    editorRef,
  } = useEsriMap({
    ...restProps,
    printable,
    handleEditorSubmit,
    enableEditor,
    editing,
    layerSwitch,
  });

  useEffect(() => {
    if (editing && certGeo) {
      const polygon = certGeo?.geometry?.coordinates?.length
        ? {
          type: 'FeatureCollection',
          features: certGeo.geometry.coordinates.map((item) => ({
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates:
              item.length > 1 ? [item] : item,
            },
            properties: certGeo.properties,
          })),
        }
        : null;
      setPolygonArea(area(polygon));
    }
  }, [editing, certGeo]);
  
  useEffect(() => {
    if (mapView.current) {
      mapView.current.on('pointer-move', (event) => {
        const point = mapView.current.toMap({ x: event.x, y: event.y });
        if (point) {
          setCoordinates({
            x: point.longitude.toFixed(7),
            y: point.latitude.toFixed(7),
          });
        }
      });
    }
  }, [mapView]);

  useEffect(() => {
    async function getScreenShot() {
      await mapView.current.takeScreenshot();
    }
    if (mapView.current && exposeMapView && mapLoaded) {
      exposeMapView(mapView.current);
      getScreenShot();
    }
    if (mapLoaded) {
      const mapViewElement = document.querySelector('.esri-view');
      if (mapViewElement) {
        mapViewElement.style.setProperty('--esri-view-outline', 'none');
      }
    }
  }, [mapView, mapLoaded]);

  useEffect(() => {
    if (importGeometry && importGeometry.length && mapRef.current) {
      const features = importGeometry
        .map((cf, index) => {
          const polygons = cf.coordinates.map((coord) => ({
            geometry: new Polygon({
              rings: coord,
            }),
            attributes: {
              ObjectID: index, // Unique identifier for each feature
              type: 'imports',
            },
          }));

          return polygons;
        })
        .flat();

      const importLayer = new FeatureLayer({
        id: 'imports',
        source: features,
        renderer: {
          type: 'simple',
          symbol: {
            type: 'simple-fill', // autocasts as new SimpleFillSymbol()
            color: [219, 171, 245, 0.4],
            outline: {
              color: '#af53e0',
              width: 5,
            },
          },
        },
        objectIdField: 'ObjectID', // Field for unique ObjectID
        fields: [
          {
            name: 'ObjectID',
            alias: 'ObjectID',
            type: 'oid',
          },
        ],
        minScale: 72224,
      });
      mapRef.current.add(importLayer);
    }
  }, [importGeometry, mapRef]);

  function handleExpandedChange() {
    const map = mapView.current;
    setExpanded((x) => !x);

    setTimeout(() => {
      // map.resize();
      let { zoom } = mapView;
      if (expanded) zoom -= 2.75;
      else zoom += 2.75;
      map.zoom = zoom;
    });
  }

  async function printMap() {
    const screenshot = await mapView.current.takeScreenshot();
    const docDefinition = {
      pageSize: 'A4',
      pageOrientation: 'landscape',
      content: [
        {
          image: screenshot.dataUrl,
          width: 600,
          margin: [80, 0],
        },
      ],
      defaultStyle: {
        fontSize: 11,
        font: 'Arial',
      },
    };
    createPdf(docDefinition);
  }

  async function handleSave() {
    const { items } = sketchGraphicLayerRef?.current?.graphics;

    const featuresEmpty = !items.length;

    const geometry = featuresEmpty
      ? null
      : {
        type: editingPolygon
          ? items.length > 1
            ? 'MultiPolygon'
            : 'Polygon'
          : 'Point',
      };
    if (!featuresEmpty) {
      const coordinates = [];
      await Promise.all(
        items.map(async ({ geometry }) => {
          if (geometry.spatialReference.wkid !== 4326) {
            const geographicGeometry = await project(
              geometry,
              SpatialReference.WGS84,
            );
            coordinates.push(geographicGeometry.rings);
          } else {
            coordinates.push(geometry.rings);
          }
        }),
      );
      geometry.coordinates = items.length > 1 ? coordinates : coordinates[0];
    }

    if (printable) {
      if (geometry) {
        const { default: exportGeoJSON } = await import(
          '../../utils/exportGeoJSON'
        );
        exportGeoJSON(geometry, {}, 'Map');
      }
    }
  }

  async function importGeo() {
    const [{ default: fileUpload }, { default: parseGeoJSON }] = await Promise.all([
      import('../../utils/fileUpload'),
      import('../../utils/parseGeoJSON'),
    ]);

    fileUpload('geojson', async (result) => {
      const geometry = parseGeoJSON(result);

      if (!geometry) return;

      setImportGeometry([...importGeometry, geometry]);
    });
  }

  function handleEditingChange() {
    if (editing) {
      editorRef.current.cancelWorkflow();
    }
    setEditing((x) => !x);
  }

  return (
    <div
      className={`GenericMap leaflet-control-container leaflet-touch ${
        expanded ? ' expanded' : height
      }`}
    >
      <div className={`relative ${height}`}>
        <div className={`mapDiv w-full ${height}`} ref={mapDiv} />
        {coordinates && (
          <div className="coordinate-section">
            {coordinates.x} | {coordinates.y}
          </div>
        )}
        {editing && polygonArea ? (
          <div className="area-container">
            <div className="area open">{polygonArea} acres</div>
          </div>
        ) : null}
        <div className="absolute top-2 left-2 flex flex-col">
          {searchable && <MapSearch mapRef={mapView} />}
          <div className="mt-2">
            {expandable && (
              <button
                type="button"
                className="h-8 min-h-8 w-8 bg-white flex items-center justify-center"
                onClick={handleExpandedChange}
              >
                {expanded ? (
                  <ArrowsPointingInIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                  />
                ) : (
                  <ArrowsPointingOutIcon
                    className="h-4 w-4"
                    aria-hidden="true"
                  />
                )}
              </button>
            )}
            {canSaveGeoJSON && (
              <button
                type="button"
                className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
                onClick={handleSave}
              >
                <ArrowDownTrayIcon className="h-4 w-4" aria-hidden="true" />
              </button>
            )}
            {enableEditor && (
              <button
                type="button"
                className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
                onClick={handleEditingChange}
              >
                {editing ? (
                  <XMarkIcon className="h-4 w-4" aria-hidden="true" />
                ) : (
                  <PencilIcon className="h-4 w-4" aria-hidden="true" />
                )}
              </button>
            )}
            {printable && (
              <>
                <button
                  type="button"
                  className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
                  onClick={printMap}
                >
                  <PrinterIcon className="h-4 w-4" aria-hidden="true" />
                </button>
                <button
                  type="button"
                  className="h-8 min-h-8 w-8 bg-white flex items-center justify-center border-t border-map-border"
                  onClick={importGeo}
                >
                  <ArrowUpOnSquareIcon className="h-4 w-4" aria-hidden="true" />
                </button>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EsriMap;
